import { LocalisationKey } from 'src/lang/en';
import { StatusType } from 'src/payments/domain/CheckoutOnboardingInformationDraft';

type RequirementReasonCodeDto = keyof typeof requirementsReasonTranslationKeys;

export const requirementsReasonTranslationKeys: Record<string, LocalisationKey> = {
  'required': 'settings.payments.checkout.onboarding.requirementsDue.reason.required',
  'unidentified_details': 'settings.payments.checkout.onboarding.requirementsDue.reason.unidentified_details',
  'verification_failed': 'settings.payments.checkout.onboarding.requirementsDue.reason.verification_failed',
  'ownership_data_missing': 'settings.payments.checkout.onboarding.requirementsDue.reason.ownership_data_missing',
  'invalid_company_name': 'settings.payments.checkout.onboarding.requirementsDue.reason.invalid_company_name',
  'document_expired': 'settings.payments.checkout.onboarding.requirementsDue.reason.document_expired',
  'invalid_document': 'settings.payments.checkout.onboarding.requirementsDue.reason.invalid_document',
  'invalid_company_document': 'settings.payments.checkout.onboarding.requirementsDue.reason.invalid_company_document',
  'document_unreadable': 'settings.payments.checkout.onboarding.requirementsDue.reason.document_unreadable',
  'document_unverifiable': 'settings.payments.checkout.onboarding.requirementsDue.reason.document_unverifiable',
  'document_type_invalid': 'settings.payments.checkout.onboarding.requirementsDue.reason.document_type_invalid',
  'document_mismatch_submitted_individual_name': 'settings.payments.checkout.onboarding.requirementsDue.reason.document_mismatch_submitted_individual_name',
  'document_mismatch_submitted_date_of_birth': 'settings.payments.checkout.onboarding.requirementsDue.reason.document_mismatch_submitted_date_of_birth',
  'document_mismatch_submitted_address': 'settings.payments.checkout.onboarding.requirementsDue.reason.document_mismatch_submitted_address',
  'document_submitted_mismatch_ssn': 'settings.payments.checkout.onboarding.requirementsDue.reason.document_submitted_mismatch_ssn',
  'document_do_not_match_user_data': 'settings.payments.checkout.onboarding.requirementsDue.reason.document_do_not_match_user_data',
  'invalid_company_registered_address': 'settings.payments.checkout.onboarding.requirementsDue.reason.invalid_company_registered_address',
};


export interface RequirementDueDto {
  field: string;
  reason: RequirementReasonCodeDto;
  message?: string;
}


export interface CheckoutOnboardingInformationDto {
  draft: 'true' | 'false';
  businessRegistrationNumber?: string;
  businessType?: string;
  legalName?: string
  tradingName?: string;
  primaryAddress?: CheckoutFormatAddressDto;
  registeredAddress?: CheckoutFormatAddressDto;
  contact?: {
    phone?: string;
    email?: string;
  };
  annualProcessingVolume?: number;
  averageTransactionValue?: number;
  highestTransactionValue?: number;
  representatives?: RepresentativeDto[]
  bankAccount?: {
    bic?: string;
    iban?: string;
    accountNumber?: string;
    bankCode?: string;
    bankStatement?: string;
  },
  incorporationDocument?: string;
  dateOfIncorporation?: string;
  articlesOfAssociationDocument?: string,
  shareholderStructureDocument?: string,
  proofOfPrincipalAddressDocument?: string,
  consent?: boolean;
  requirementsDue?: RequirementDueDto[]
  status: StatusType
}

type RepresentativeRoleDto = 'ubo' | 'legal_representative' | 'authorised_signatory' | 'control_person' | 'director';


export interface CheckoutFormatAddressDto {
  addressLine1?: string;
  city?: string;
  zip?: string;
  country?: string;
}

export type PersonalIdTypeDto =
  'passport'
  | 'national_identity_card'
  | 'driving_license'
  | 'citizen_card'
  | 'residence_permit'
  | 'electoral_id';


export interface RepresentativeDto {
  firstName?: string;
  lastName?: string;
  address?: CheckoutFormatAddressDto
  identificationType?: PersonalIdTypeDto;
  identificationFrontDocument?: string;
  identificationBackDocument?: string;
  dateOfBirth?: string;
  countryOfBirth?: string;
  ownershipPercentage?: number;
  email?: string;
  type?: 'individual' | 'company';
  legalName?: string;
  tradingName?: string;
  companyPosition?: string;
  certifiedAuthorisedSignatoryDocument?: string;
  roles?: RepresentativeRoleDto[]
}

