import React, { useCallback, useEffect, useMemo, useState } from 'react';
import styled from 'styled-components';
import { FormattedMessage, useIntl } from 'src/app/i18n/TypedIntl';
import { useVenueRepository } from '../../hook';
import { VenueDetails } from '../../types';
import { useBoxRepository } from '../../../box/hooks';
import { BoxDetails } from '../../../box/domain/BoxDetails';
import { Box } from '../../../box/domain/Box';
import { BoxSelector } from '../../../box/components';
import { VenueAndBoxComponents } from '../AppVenueSettings/component/VenueAndBoxComponents';
import { getSpacing } from '../../../stylesheet';
import { EnrollmentId } from 'src/business/domain/Enrollment';
import { CircularProgress, Switch } from '@mui/material';

type VenueAndBoxSettingsProps = {
  enrollmentId: EnrollmentId;
};

const Container = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
`;

const SelectorContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: ${getSpacing(3)};
  justify-content: end;
  width: 100%;
`;

const SelectorTitle = styled.div`
  margin-left: ${getSpacing(2)};
  margin-bottom: ${getSpacing(1)};
`;

const AdvancedOptionsToggleSection = styled.div`
  display: flex;
  justify-content: flex-end;
  padding: ${getSpacing(1)};
  width: 100%;
`;

const AdvancedOptionsToggleLabel = styled.div`
  padding: ${getSpacing(1)};
`;

const HeaderContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

// eslint-disable-next-line complexity
export const VenueAndBoxSettings = ({ enrollmentId }: VenueAndBoxSettingsProps) => {
  const intl = useIntl();

  const venueRepository = useVenueRepository();
  const boxRepository = useBoxRepository();

  const [venue, setVenue] = useState<VenueDetails>();
  const [boxes, setBoxes] = useState<Box[]>([]);
  const [box, setBox] = useState<BoxDetails>();

  const [displayAdvancedOptions, setDisplayAdvancedOptions] = useState<boolean>(false);

  useEffect(() => {
    venueRepository.getVenue(enrollmentId).then(setVenue);
  }, [venueRepository, enrollmentId]);

  const loadVenueWithDetectedBox = useCallback(
    (enrollmentIdCallback: EnrollmentId) => {
      boxRepository.getVenueBoxes(enrollmentIdCallback).then((venueBoxes) => {
        setBoxes(venueBoxes);

        if (venueBoxes.length === 1) {
          boxRepository.getBoxDetails(venueBoxes[0].id).then(setBox);
        }
      });
    },
    [boxRepository],
  );

  const onBoxChanged = (changedBox: Box) => {
    setBox(undefined);
    setTimeout(() => boxRepository.getBoxDetails(changedBox.id).then(setBox), 0);
  };

  useEffect(() => {
    loadVenueWithDetectedBox(enrollmentId);
  }, [loadVenueWithDetectedBox, enrollmentId]);

  const useVenue = useMemo((): boolean => {
    if (boxes?.length === 1) {
      return true;
    }

    return box === undefined;
  }, [box, boxes]);

  if (!venue) {
    return <CircularProgress size="large" />;
  }

  return (
    <Container>
      <HeaderContainer>
        {boxes.length > 1 && (
          <SelectorContainer>
            <SelectorTitle>
              <FormattedMessage id="venue.settings.selector.title" defaultMessage="Scope settings" />
            </SelectorTitle>
            <BoxSelector
              enrollmentId={enrollmentId}
              onBoxUnselected={() => setBox(undefined)}
              onBoxSelected={onBoxChanged}
              initialBoxes={boxes}
              emptyMessage={intl.formatMessage({
                id: 'venue.settings.selector.global-venue',
                defaultMessage: 'global to the venue',
              })}
            />
          </SelectorContainer>
        )}
        <AdvancedOptionsToggleSection>
          <AdvancedOptionsToggleLabel>
            <FormattedMessage id="venue.settings.show-advanced-settings" defaultMessage="display advanced settings" />
          </AdvancedOptionsToggleLabel>
          <Switch checked={displayAdvancedOptions} onChange={(_, val)=>setDisplayAdvancedOptions(val)} disabled={false} />
        </AdvancedOptionsToggleSection>
      </HeaderContainer>
      <VenueAndBoxComponents
        venue={venue}
        setVenue={setVenue}
        useVenue={useVenue}
        box={box}
        setBox={setBox}
        displayAdvancedOptions={displayAdvancedOptions}
      />
    </Container>
  );
};
