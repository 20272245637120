import { Button, CircularProgress, Stack, Typography } from '@mui/material';
import React from 'react';
import { useBusinessIdOrThrow } from '../business/hooks/useBusinessId';
import { useGetBusinessLoyaltyReference } from './queries/useGetBusinessLoyaltyReference';
import { useIntl } from 'src/app/i18n/TypedIntl';
import { pongoOnboardingPath } from 'src/app/navigation/pathHelpers';
import { useNavigate } from 'react-router';

export const PongoOnboardingLink = () => {
  const businessId = useBusinessIdOrThrow();
  const navigate = useNavigate();
  const { formatMessage } = useIntl();
  const {
    data: loyaltyBusiness,
    isLoading: loyaltyReferenceIsLoading,
  } = useGetBusinessLoyaltyReference(businessId);


  if (loyaltyReferenceIsLoading) {
    return <CircularProgress />;
  }

  return (
    <Stack gap="16px" maxWidth="576px">
      <Typography variant="h5">
        {formatMessage({ id: 'loyalty.onboarding.title' }, { loyaltyReference: 'Pongo' })}
      </Typography>

      {!loyaltyBusiness && (<Button
        variant="contained"
        onClick={() => {
          navigate(pongoOnboardingPath(businessId));
        }}
      >
        {'Go to'} {formatMessage({ id: 'loyalty.onboarding.title' }, { loyaltyReference: 'Pongo' })}
      </Button>)}

    </Stack>
  );
};
