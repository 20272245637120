import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { useIntl } from 'src/app/i18n/TypedIntl';
import { OrderingMenu } from 'src/ordering/orderingMenus/domain/OrderingMenu';
import { useGetMenuWithProducts, useUpdateMenuUpsells } from 'src/ordering/orderingMenus/hooks';
import { OrderingMenuWithProducts } from 'src/ordering/orderingMenus/domain/OrderingMenuWithProducts';
import { ExpandablePanelButton } from 'src/ordering/common/components/Settings/ExpandablePanelButton';
import { colorPalette, getSpacing } from 'src/ordering/stylesheet';
import { UpsellProduct } from './UpsellProduct';
import { OrderingProduct } from 'src/ordering/orderingMenus/domain/OrderingProduct';
import { usePushNotification } from 'src/ordering/common/components/Notifications.hook';
import { CircularProgress } from '@mui/material';

export type UpsellBoxMenuProps = {
  menu: OrderingMenu;
};

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  border: 1px solid ${colorPalette.grey300};
  border-radius: ${getSpacing(2)};
  padding: ${getSpacing(2)};
  margin-bottom ${getSpacing(2)};
`;

export const Header = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: space-between;
  cursor: pointer;
`;

export const MenuName = styled.div`
  display: flex;
`;

export const Products = styled.div`
  margin-top: ${getSpacing(1)};
  display: flex;
  flex-direction: column;
`;

const isSupportedProduct = (product: OrderingProduct): boolean => {
  if (product.isVariant) {
    return true;
  }

  return product.type === 'PRODUCT';
};

export const UpsellBoxMenu = ({ menu }: UpsellBoxMenuProps) => {
  const intl = useIntl();
  const [, pushNotification] = usePushNotification();

  const [, getMenuProducts] = useGetMenuWithProducts();
  const [, updateMenuUpsells] = useUpdateMenuUpsells(menu.id);
  const [expanded, expand] = useState<boolean>(false);
  const [menuWithProducts, setMenuWithProducts] = useState<OrderingMenuWithProducts>();

  useEffect(() => {
    getMenuProducts(menu.id).then(setMenuWithProducts);
  }, [getMenuProducts, menu.id]);

  if (!menuWithProducts) {
    return (
      <Container>
        <Header onClick={() => expand(!expanded)}>
          <MenuName>{menu.name}</MenuName>
          <CircularProgress size="large" />
          ;
        </Header>
      </Container>
    );
  }

  const supportedProducts = menuWithProducts.products
    .filter((product) => isSupportedProduct(product))
    .filter((product) => product.price.amount > 0);

  const isProductSelected = (product: OrderingProduct, recommendedUpsells: OrderingProduct[]): boolean => recommendedUpsells.findIndex((upsells) => upsells.id === product.id) >= 0;

  const buildNewRecommendedUpsells = (product: OrderingProduct, selected: boolean): OrderingProduct[] => {
    if (selected) {
      return [...menuWithProducts.recommendedUpsells, product];
    }

    return menuWithProducts.recommendedUpsells.filter((upsell) => upsell.id !== product.id);
  };

  const productToggled = async (product: OrderingProduct, selected: boolean) => {
    const recommendedUpsells = buildNewRecommendedUpsells(product, selected);

    await updateMenuUpsells(recommendedUpsells.map((recommendedProduct) => recommendedProduct.id));

    setMenuWithProducts({ ...menuWithProducts, recommendedUpsells });

    pushNotification(intl.formatMessage({ id: 'venue.settings.updated', defaultMessage: 'venue settings updated' }));
  };

  return (
    <Container>
      <Header onClick={() => expand(!expanded)}>
        <MenuName>
          {menu.name}
          {' '}
          (
          {menuWithProducts.recommendedUpsells.length}
          )
        </MenuName>
        <ExpandablePanelButton expanded={expanded} expand={expand} />
      </Header>
      {expanded && (
        <Products>
          {supportedProducts.map((product) => (
            <UpsellProduct
              key={product.id}
              product={product}
              isSelected={isProductSelected(product, menuWithProducts?.recommendedUpsells)}
              toggleProductSelection={productToggled}
            />
          ))}
        </Products>
      )}
    </Container>
  );
};
