import React from 'react';
import { Tooltip, TooltipProps } from '@mui/material';

const SundayTooltip: React.FC<TooltipProps> = ({ title, placement, children }: TooltipProps) => (
  <Tooltip
    enterTouchDelay={0}
    arrow
    title={title}
    placement={placement}
  >
    {children}
  </Tooltip>
);

export default SundayTooltip;
