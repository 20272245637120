import { CurrencyCode, Money } from '@sundayapp/web-money';
import { Payments } from './Payments';
import { safeSumMoney } from 'src/utils/MoneyUtils';
import { Walkouts } from 'src/operations/end-of-service/model/Walkouts';
import {
  ReconciliationOperation,
  reconciliationOperationKind,
  reconciliationOperationOrigin,
  ReconciliationReporting, ReconciliationReportingDigest,
} from './ReconciliationReportingDigest';
import { products } from 'src/operations/end-of-service/model/products';

const operationsSumSales = (operations: ReconciliationOperation[], currency: CurrencyCode): Money =>
  safeSumMoney(operations.map((operation) => operation.sales), currency);

const operationsSumTips = (operations: ReconciliationOperation[], currency: CurrencyCode): Money =>
  safeSumMoney(operations.map((operation) => operation.tips), currency);


const createWalkoutDigest = (walkouts: Walkouts, currency: CurrencyCode): ReconciliationReporting => {
  const operations = walkouts.walkouts.map((walkout): ReconciliationOperation => ({
    id: walkout.id,
    sales: walkout.salesAmount,
    tips: walkout.tipsAmount,
    at: walkout.createdAt,
    kind: reconciliationOperationKind.WALKOUT,
    origin: reconciliationOperationOrigin.SUPPORT,
  }));

  return {
    operations,
    sales: operationsSumSales(operations, currency),
    tips: operationsSumTips(operations, currency),
  };
};

const createNotNotifiedPaymentDigest = (payments: Payments, currency: CurrencyCode): ReconciliationReporting => {
  const filteredPayments = payments.payments.filter((payment) => !payment.notifiedToPos && !payment.isPdqFastPayment);
  const operations = filteredPayments.map((payment): ReconciliationOperation => ({
    id: payment.id,
    sales: payment.salesAmount,
    tips: payment.tipsAmount,
    at: new Date(payment.processedAt),
    kind: reconciliationOperationKind.NOT_NOTIFIED_PAYMENT,
    origin: payment.origin === products.PDQ ? reconciliationOperationOrigin.PDQ : reconciliationOperationOrigin.QR_CODE,
  }),
  );

  return {
    operations,
    sales: operationsSumSales(operations, currency),
    tips: operationsSumTips(operations, currency),
  };
};

const createFastPaymentDigest = (payments: Payments, currency: CurrencyCode): ReconciliationReporting => {
  const filteredPayments = payments.payments.filter((payment) => payment.isPdqFastPayment);
  const operations = filteredPayments.map((payment): ReconciliationOperation => ({
    id: payment.id,
    sales: payment.salesAmount,
    tips: payment.tipsAmount,
    at: new Date(payment.processedAt),
    kind: reconciliationOperationKind.PDQ_FAST_PAYMENT,
    origin: reconciliationOperationOrigin.PDQ,
  }),
  );

  return {
    operations,
    sales: operationsSumSales(operations, currency),
    tips: operationsSumTips(operations, currency),
  };
};

export function createReconciliationReportingDigest(
  walkouts: Walkouts,
  payments: Payments,
  currency: CurrencyCode,
): ReconciliationReportingDigest {
  return {
    walkouts: createWalkoutDigest(walkouts, currency),
    notNotifiedPayments: createNotNotifiedPaymentDigest(payments, currency),
    pdqFastPayments: createFastPaymentDigest(payments, currency),
  };
}
