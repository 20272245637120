import React, { useEffect } from 'react';
import { Box } from '@mui/material';
import { NavigationMenu } from 'src/app/navigation/navigationMenu/NavigationMenu';
import { MainLayoutContainer } from 'src/app/layouts/MainLayout';
import { ServiceType } from 'src/domain/venue/ServiceType';
import AccountingSvg from 'src/app/component/icons/AccountingSvg';
import { PaletteTheme } from 'src/app/theme/PaletteTheme';
import AccountingPlainSvg from 'src/app/component/icons/AccountingPlainSvg';
import { userRoles } from 'src/domain/user/UserRole';
import { InfoAccounting } from 'src/accounting/InfoAccounting';
import { Outlet } from 'react-router-dom';
import { useAuthenticatedUserOrThrow } from 'src/auth/hooks/useAuthenticatedUserOrThrow';
import { useNavigate } from 'react-router';
import { venueHomePath } from 'src/app/navigation/pathHelpers';
import { AuthenticatedUser, relationTypes } from 'src/auth/domain/user';
import { NavigationItem } from 'src/app/navigation/navigationMenu/NavigationItems';

const accountingEntries = (user: AuthenticatedUser): NavigationItem[] => {
  if (!user.relations.some((relation) => relation.relation === relationTypes.can_access_financials)) {
    return [];
  }
  return [
    {
      icon: <AccountingSvg color={PaletteTheme.menu.icon} />,
      selectedIcon: <AccountingPlainSvg />,
      text: 'accounting.title',
      authorizedRoles: [userRoles.ADMIN, userRoles.GENERAL_MANAGER, userRoles.MANAGER],
      availableTo: {
        products: [
          ServiceType.PAY_AT_TABLE,
          ServiceType.ORDER_AND_PAY,
          ServiceType.SMB_PAY,
          ServiceType.PAYMENT_TERMINAL,
        ],
      },
      availableForNoPos: false,
      tooltip: 'menu.tooltip.accounting',
      subItems: [
        {
          text: 'accounting.invoice_tab',
          route: '/multi-locations/accounting/invoices',
          authorizedRoles: [userRoles.ADMIN, userRoles.GENERAL_MANAGER, userRoles.MANAGER],
          availableTo: {
            products: [
              ServiceType.PAY_AT_TABLE,
              ServiceType.ORDER_AND_PAY,
              ServiceType.SMB_PAY,
              ServiceType.PAYMENT_TERMINAL,
            ],
            stillShowWhenChurned: true,
          },
          availableForNoPos: false,
          subItems: [],
        },
      ],
      banner: <InfoAccounting />,
    },
  ];
};

export const menuItems = (user: AuthenticatedUser) => {
  return [...accountingEntries(user)];
};

export const MultiLocationLayout = () => {
  document.title = 'multi-locations dashboard';
  const user = useAuthenticatedUserOrThrow();
  const navigate = useNavigate();

  // TODO: make it a loader or something
  useEffect(() => {
    if (user.relations.length < 2) {
      navigate(venueHomePath(user.claims[0].businessId));
    }
  }, [user, navigate]);
  return (
    <>
      <Box sx={{ display: 'flex' }}>
        <NavigationMenu menuItems={menuItems(user)} />

        <MainLayoutContainer component="main">
          <Outlet />
        </MainLayoutContainer>
      </Box>
    </>
  );
};
