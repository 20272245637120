import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { useIntl } from 'src/app/i18n/TypedIntl';
import { useVenueRepository } from '../../hook';
import { VenueDetails } from '../../types';
import { PayWithCashVenueSetting } from 'src/ordering/venues/pages';
import { FoldableCategoriesVenueSetting } from 'src/ordering/venues/pages';
import { CutleryVenueSetting } from 'src/ordering/venues/pages';
import { GroupingVenueSetting } from 'src/ordering/venues/pages';
import { CustomerRequiredDataVenueSetting } from 'src/ordering/venues/pages';
import { TimezoneVenueSetting } from 'src/ordering/venues/pages';
import { ServiceFeeRateVenueSetting } from 'src/ordering/venues/pages';
import { BoxRankingVenueSetting } from '../../../foodCourt/components/BoxRanking/BoxRankingVenueSetting';
import { useBoxRepository } from '../../../box/hooks';
import { SettingsCategory } from '../AppVenueSettings/component/SettingsCategory';
import {
  BusySetting,
  ClickAndCollectBoxSetting,
  ConfirmationMessageBoxSetting,
  OrderNotesBoxSetting,
  OrderNotificationBoxSettings,
  PacingBoxSetting,
  QueueingSetting,
  UpsellBoxSetting,
} from '../../../box';
import { BoxDetails } from '../../../box/domain/BoxDetails';
import { EnrollmentId } from 'src/business/domain/Enrollment';
import { CircularProgress } from '@mui/material';

type VenueSettingsProps = {
  enrollmentId: EnrollmentId;
  boxId?: string;
};

const Container = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
`;

// eslint-disable-next-line complexity
export const VenueSettings = ({ enrollmentId, boxId }: VenueSettingsProps) => {
  const intl = useIntl();
  const venueRepository = useVenueRepository();
  const boxRepository = useBoxRepository();

  const [venue, setVenue] = useState<VenueDetails>();
  const [box, setBox] = useState<BoxDetails>();

  useEffect(() => {
    venueRepository.getVenue(enrollmentId).then(setVenue);
  }, [venueRepository, enrollmentId]);

  useEffect(() => {
    if (!boxId) {
      return;
    }

    boxRepository.getBoxDetails(boxId).then(setBox);
  }, [boxId, boxRepository]);

  if (!venue) {
    return <CircularProgress size="large" />;
  }

  return (
    <Container>
      <SettingsCategory
        title={intl.formatMessage({
          id: 'venue.settings.category.products-menus',
          defaultMessage: 'Products and menus',
        })}
      >
        {box && <UpsellBoxSetting box={box} />}
        <FoldableCategoriesVenueSetting venue={venue} setVenue={setVenue} />
        {box && <OrderNotesBoxSetting box={box} setBox={setBox} />}
      </SettingsCategory>

      <SettingsCategory
        title={intl.formatMessage({
          id: 'venue.settings.category.experience',
          defaultMessage: 'Customise the experience',
        })}
      >
        <BoxRankingVenueSetting venue={venue} setVenue={setVenue} />
        {box && <ConfirmationMessageBoxSetting box={box} setBox={setBox} />}
        {box && <OrderNotificationBoxSettings venue={venue} box={box} setBox={setBox} />}
        <CutleryVenueSetting venue={venue} setVenue={setVenue} />
      </SettingsCategory>

      <SettingsCategory
        title={intl.formatMessage({
          id: 'venue.settings.category.operations',
          defaultMessage: 'Operations',
        })}
      >
        <GroupingVenueSetting venue={venue} setVenue={setVenue} />
        {box && <PacingBoxSetting box={box} setBox={setBox} />}
        {box && <BusySetting box={box} setBox={setBox} />}
        {box && <ClickAndCollectBoxSetting venue={venue} box={box} setBox={setBox} />}
        {box && <QueueingSetting box={box} setBox={setBox} />}
        <PayWithCashVenueSetting venue={venue} setVenue={setVenue} />
      </SettingsCategory>

      <SettingsCategory
        title={intl.formatMessage({
          id: 'venue.settings.category.other',
          defaultMessage: 'Other',
        })}
      >
        <CustomerRequiredDataVenueSetting venue={venue} setVenue={setVenue} />
        <TimezoneVenueSetting venue={venue} setVenue={setVenue} />
        <ServiceFeeRateVenueSetting venue={venue} setVenue={setVenue} />
      </SettingsCategory>
    </Container>
  );
};
