import React, { useEffect } from 'react';
import { useNavigate } from 'react-router';
import { venuePath } from 'src/app/navigation/pathHelpers';
import { RouteDefinitions } from 'src/app/RouteDefinitions';
import { AuthenticatedUser } from '../../domain/user';
import { useCurrentUser } from '../../component/UserProvider';
import { BusinessReferentialRepositoryHttp } from 'src/business/infrastructure/BusinessReferentialRepositoryHttp';
import { Business } from 'src/business/domain/Business';
import { ConfigurationLoader } from 'src/configuration/ConfigurationLoader';
import { CircularProgress } from '@mui/material';

export const SignInConfirmation = () => {
  const navigate = useNavigate();
  const currentUser = useCurrentUser();

  const redirectAfterLogin = async (user: AuthenticatedUser) => {
    if (user.impersonated || (user.firstName && user.lastName)) {
      const venueId = sessionStorage.getItem('redirectVenueId');
      sessionStorage.removeItem('redirectVenueId');
      if (venueId) {
        navigate(venuePath(venueId));
      } else if (user.claims.length === 0) {
        navigate(RouteDefinitions.NoVenue.path);
      } else {
        navigate(venuePath(user.claims[0].businessId));
      }
    } else {
      const businessId = user.claims[0].businessId;
      const configuration = ConfigurationLoader.load();
      const businessReferentialRepository = new BusinessReferentialRepositoryHttp(configuration.venueApiBaseUrl);
      const business: Business | undefined = await businessReferentialRepository.getById(businessId);
      if (business?.isInitialSetupCompleted) {
        navigate(RouteDefinitions.CompleteSignUp.path);
      } else {
        // Navigate to venue path to display initial-setup component
        navigate(venuePath(businessId));
      }
    }
  };

  useEffect(() => {
    if (!currentUser.authenticated) {
      return;
    }
    redirectAfterLogin(currentUser);
  }, [ currentUser.authenticated ]);

  return (
    <CircularProgress color="inherit" size="large" />
  );
};
