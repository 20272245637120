import { ThemeTooltip } from 'src/app/component/ThemeTooltip';
import { BusinessSelectionIcon } from 'src/business/components/BusinessSelectionIcon';
import React from 'react';
import { CurrentName, Item, MultiLocationsItemProps, Name } from 'src/business/components/MultiLocationsSelectionItem';

export function MultilocationsSelectionItem({
  isCompact = false,
  isCurrent = false,
  iconSize = 24,
}: MultiLocationsItemProps) {
  const name = 'Multi-locations';
  const getCurrentName = () => (
    <ThemeTooltip title={name} placement="right">
      <CurrentName>{name}</CurrentName>
    </ThemeTooltip>
  );

  const getName = () => <Name>{name}</Name>;
  return (
    <Item isCurrent={isCurrent}>
      <BusinessSelectionIcon name={name} imageUrl={undefined} size={iconSize} />
      {!isCompact && <>{isCurrent ? getCurrentName() : getName()}</>}
    </Item>
  );
}
