import { Variables } from './Variables';

export class EnvBasedVariables implements Variables {
  private env = import.meta.env;

  get VITE_APPLICATION_VERSION() {
    return this.env.VITE_APPLICATION_VERSION;
  }

  get VITE_DATADOG_APPLICATION_ID() {
    return this.env.VITE_DATADOG_APPLICATION_ID;
  }

  get VITE_DATADOG_CLIENT_TOKEN() {
    return this.env.VITE_DATADOG_CLIENT_TOKEN;
  }

  get VITE_DATADOG_RUM_TOKEN() {
    return this.env.VITE_DATADOG_RUM_TOKEN;
  }

  get VITE_ENV() {
    return this.env.VITE_ENV;
  }

  get VITE_ENV_NAME() {
    return this.env.VITE_ENV_NAME;
  }

  get VITE_FEATURE_FLAG_ACCOUNTING_INCONSISTENCY() {
    return this.env.VITE_FEATURE_FLAG_ACCOUNTING_INCONSISTENCY;
  }

  get VITE_FEATURE_FLAG_ACCOUNTING_SERVICE_CHARGE() {
    return this.env.VITE_FEATURE_FLAG_ACCOUNTING_SERVICE_CHARGE;
  }

  get VITE_FRONT_PORT() {
    return this.env.VITE_FRONT_PORT;
  }

  get VITE_FRONT_URL() {
    return this.env.VITE_FRONT_URL;
  }

  get VITE_HOSTNAME_B2C_WEB_APP() {
    return this.env.VITE_HOSTNAME_B2C_WEB_APP;
  }

  get VITE_HOSTNAME_WAITER_WEB_APP() {
    return this.env.VITE_HOSTNAME_WAITER_WEB_APP;
  }

  get VITE_INTERCOM_ID() {
    return this.env.VITE_INTERCOM_ID;
  }

  get VITE_PAYMENT_URL() {
    return this.env.VITE_PAYMENT_URL;
  }

  get VITE_VENUE_FIREBASE_CONFIG() {
    return this.env.VITE_VENUE_FIREBASE_CONFIG;
  }

  get VITE_VPOS_URL() {
    return this.env.VITE_VPOS_URL;
  }

  get VITE_VPOS_REFRESH_BILL_URL() {
    return this.env.VITE_VPOS_REFRESH_BILL_URL;
  }

  get VITE_SEGMENT_TOKEN() {
    return this.env.VITE_SEGMENT_TOKEN;
  }

  get VITE_GLOBAL_CONFIGURATION_URL() {
    return this.env.VITE_GLOBAL_CONFIGURATION_URL;
  }

  get VITE_VENUE_FEEDBACK_URL() {
    return this.env.VITE_VENUE_FEEDBACK_URL;
  }

  get VITE_LOYALTY_BACKEND_URL() {
    return this.env.VITE_LOYALTY_BACKEND_URL;
  }

  get VITE_CONSENT_MANAGER_URL() {
    return this.env.VITE_CONSENT_MANAGER_URL;
  }

  get VITE_RECEIPT_URL() {
    return this.env.VITE_RECEIPT_URL;
  }

  get VITE_USER_ROLE_HINT_URL() {
    return this.env.VITE_USER_ROLE_HINT_URL;
  }

  get VITE_REVIEW_HINT_URL_EN() {
    return this.env.VITE_REVIEW_HINT_URL_EN;
  }

  get VITE_ORDERING_MERCHANT_DASHBOARD_HOSTNAME() {
    return this.env.VITE_ORDERING_MERCHANT_DASHBOARD_HOSTNAME;
  }

  get VITE_MENU_URL() {
    return this.env.VITE_MENU_URL;
  }

  get VITE_ORDERING_URL() {
    return this.env.VITE_ORDERING_URL;
  }

  get VITE_ACCOUNTING_URL() {
    return this.env.VITE_ACCOUNTING_URL;
  }

  get VITE_BOOKKEEPING_URL() {
    return this.env.VITE_BOOKKEEPING_URL;
  }

  get VITE_CONTACT_US_URL() {
    return this.env.VITE_CONTACT_US_URL;
  }

  get VITE_MERCHANT_ACCOUNTS_URL() {
    return this.env.VITE_MERCHANT_ACCOUNTS_URL;
  }

  get VITE_FLAGSMITH_ENV_ID() {
    return this.env.VITE_FLAGSMITH_ENV_ID;
  }

  get VITE_GOOGLE_OAUTH2_CLIENT_ID() {
    return this.env.VITE_GOOGLE_OAUTH2_CLIENT_ID;
  }

  get VITE_PAT_ORCHESTRATOR_URL() {
    return this.env.VITE_PAT_ORCHESTRATOR_URL;
  }

  get VITE_NEPTING_URL() {
    return this.env.VITE_NEPTING_URL;
  }

  get VITE_REVIEW_HINT_URL_FR() {
    return this.env.VITE_REVIEW_HINT_URL_FR;
  }

  get VITE_PAYMENT_TERMINAL_BACKEND_URL() {
    return this.env.VITE_PAYMENT_TERMINAL_BACKEND_URL;
  }

  get VITE_REORDER_QR_CODES_FR_URL() {
    return this.env.VITE_REORDER_QR_CODES_FR_URL;
  }

  get VITE_REORDER_QR_CODES_US_URL() {
    return this.env.VITE_REORDER_QR_CODES_US_URL;
  }

  get VITE_REORDER_QR_CODES_UK_URL() {
    return this.env.VITE_REORDER_QR_CODES_UK_URL;
  }

  get VITE_REFERRAL_FORM_FR_URL() {
    return this.env.VITE_REFERRAL_FORM_FR_URL;
  }

  get VITE_REFERRAL_FORM_UK_URL() {
    return this.env.VITE_REFERRAL_FORM_UK_URL;
  }

  get VITE_REFERRAL_FORM_DE_URL() {
    return this.env.VITE_REFERRAL_FORM_DE_URL;
  }

  get VITE_REFERRAL_FORM_US_URL() {
    return this.env.VITE_REFERRAL_FORM_US_URL;
  }

  get VITE_RECONCILIATION_API_URL() {
    return this.env.VITE_RECONCILIATION_API_URL;
  }

  get VITE_MERCHANT_ANALYTICS_API_URL() {
    return this.env.VITE_MERCHANT_ANALYTICS_API_URL;
  }

  get VITE_VENUE_REFERENTIAL_URL() {
    return this.env.VITE_VENUE_REFERENTIAL_URL;
  }

  get VITE_AUTH0_AUDIENCE() {
    return this.env.VITE_AUTH0_AUDIENCE;
  }

  get VITE_AUTH0_CLIENT_ID() {
    return this.env.VITE_AUTH0_CLIENT_ID;
  }

  get VITE_AUTH0_DOMAIN() {
    return this.env.VITE_AUTH0_DOMAIN;
  }

  get VITE_VPOS_VENUE_URL() {
    return this.env.VITE_VPOS_VENUE_URL;
  }

  get VITE_EXPERIMENT_DEPLOYMENT_KEY() {
    return this.env.VITE_EXPERIMENT_DEPLOYMENT_KEY;
  }

  get VITE_POS_REFERENCES_WITHOUT_STAFF_PERFORMANCE() {
    return this.env.VITE_POS_REFERENCES_WITHOUT_STAFF_PERFORMANCE;
  }

  get VITE_CHECKIN_BACKEND_URL() {
    return this.env.VITE_CHECKIN_BACKEND_URL;
  }

  get VITE_CHECKIN_FRONTEND_URL() {
    return this.env.VITE_CHECKIN_FRONTEND_URL;
  }

  get VITE_HOSTNAME_WAITER_APP_BACKEND() {
    return this.env.VITE_HOSTNAME_WAITER_APP_BACKEND;
  }

  get VITE_EMBEDDED_METABASE_URL() {
    return this.env.VITE_EMBEDDED_METABASE_URL;
  }

  get VITE_SUNDAY_DEVICE_MANAGER_URL() {
    return this.env.VITE_SUNDAY_DEVICE_MANAGER_URL;
  }

  get VITE_PONGO_BASE_URL() {
    return this.env.VITE_PONGO_BASE_URL;
  }

  get VITE_PONGO_CLIENT_ID() {
    return this.env.VITE_PONGO_CLIENT_ID;
  }

  get VITE_BOURDONCLE_BUSINESSES_IDS() {
    return this.env.VITE_BOURDONCLE_BUSINESSES_IDS;
  }

  get VITE_TIPS_SUGGESTION_URL() {
    return this.env.VITE_TIPS_SUGGESTION_URL;
  }

  get VITE_API_GATEWAY_URL() {
    return this.env.VITE_API_GATEWAY_URL;
  }

  get VITE_AUDIT_TRAIL() {
    return this.env.VITE_AUDIT_TRAIL;
  }

  // Done to bypass the caching issues
  get VITE_REFERRAL_REDIRECT_UK_URL() {
    return this.env.VITE_REFERRAL_REDIRECT_UK_URL;
  }

  get VITE_REFERRAL_REDIRECT_FR_URL() {
    return this.env.VITE_REFERRAL_REDIRECT_FR_URL;
  }

  get VITE_REFERRAL_REDIRECT_DE_URL() {
    return this.env.VITE_REFERRAL_REDIRECT_DE_URL;
  }

  get VITE_REFERRAL_REDIRECT_US_URL() {
    return this.env.VITE_REFERRAL_REDIRECT_US_URL;
  }

  get VITE_OG_ENROLLMENT_ID() {
    return this.env.VITE_OG_ENROLLMENT_ID;
  }

  get VITE_ENABLE_HYBRID_ORDERING_AREAS() {
    return this.env.VITE_ENABLE_HYBRID_ORDERING_AREAS;
  }

  get VITE_ENABLE_POS_PRODUCT_MODIFICATIONS() {
    return this.env.VITE_ENABLE_POS_PRODUCT_MODIFICATIONS;
  }

  get VITE_BUSINESS_IDS_NOT_SUPPORTING_MENU_PREVIEW() {
    return this.env.VITE_BUSINESS_IDS_NOT_SUPPORTING_MENU_PREVIEW;
  }
}
