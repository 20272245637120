import { useIntl } from 'src/app/i18n/TypedIntl';
import { useCallback, useEffect, useMemo, useState } from 'react';
// eslint-disable-next-line no-restricted-imports
import moment, { Moment } from 'moment';
import {
  RestaurantShiftTimesOption,
  RestaurantShiftTimesOptionType,
  restaurantShiftTimesOptionTypes,
} from '../models/RestaurantShiftTimesOption';
import { VenueReferentialRepositoryHttp } from 'src/infrastructure/venue/VenueReferentialRepositoryHttp';
import { ConfigurationLoader } from 'src/configuration/ConfigurationLoader';
import { RestaurantShiftTimes, TimeString } from 'src/domain/venue/RestaurantShiftTimes';
import { useCurrentBusinessOrThrow } from 'src/business/hooks/useCurrentBusinessOrThrow';

function getDate(time: string) {
  return moment.utc(time, 'HH:mm');
}

function getShiftHour(date: Moment) {
  return { hour: date.hour(), minute: date.minute() };
}

export type RestaurantShiftTimesOptions = {
  [type in RestaurantShiftTimesOptionType]: RestaurantShiftTimesOption;
};

export const useRestaurantShiftTimesOptions = (): { options: RestaurantShiftTimesOptions, isFetching: boolean } => {
  const { formatMessage } = useIntl();
  const [restaurantShiftTimes, setRestaurantShiftTimes] = useState<RestaurantShiftTimes>({
    lunch: {
      from: '4:00' as TimeString,
      to: '14:29' as TimeString,
    },
    dinner: {
      from: '14:30' as TimeString,
      to: '3:59' as TimeString,
    },
  });

  const toLocalTimeString: (timeString: TimeString) => string = useMemo(() => {
    const formatter = new Intl.DateTimeFormat(navigator.language, { hour: 'numeric', minute: 'numeric' });

    return (timeString: TimeString) => {
      const [hour, minute] = timeString.split(':').map(Number);
      const date = new Date();
      date.setHours(hour, minute);

      return formatter.format(date);
    };
  }, [navigator.language]);

  const toLocalTimeRangeString: (from: TimeString, to: TimeString) => string = useCallback((from, to) => {
    return `${toLocalTimeString(from)} - ${toLocalTimeString(to)}`;
  }, [toLocalTimeString]);

  const [isFetching, setIsFetching] = useState<boolean>(false);
  const business = useCurrentBusinessOrThrow();
  const { venueReferentialUrl } = ConfigurationLoader.load();
  const client: VenueReferentialRepositoryHttp = new VenueReferentialRepositoryHttp(venueReferentialUrl);

  const lunchFrom = getDate(restaurantShiftTimes.lunch.from);
  const lunchTo = getDate(restaurantShiftTimes.lunch.to);
  const dinnerFrom = getDate(restaurantShiftTimes.dinner.from);
  const dinnerTo = getDate(restaurantShiftTimes.dinner.to);

  const lunch: RestaurantShiftTimesOption = {
    startAt: getShiftHour(lunchFrom),
    endAt: getShiftHour(lunchTo),
    type: restaurantShiftTimesOptionTypes.LUNCH,
    label: formatMessage({ id: 'sales_summary.select.shift.lunch' }),
    timeRangeLabel: toLocalTimeRangeString(restaurantShiftTimes.lunch.from, restaurantShiftTimes.lunch.to),
  };
  const dinner: RestaurantShiftTimesOption = {
    startAt: getShiftHour(dinnerFrom),
    endAt: getShiftHour(dinnerTo),
    type: restaurantShiftTimesOptionTypes.DINNER,
    label: formatMessage({ id: 'sales_summary.select.shift.dinner' }),
    timeRangeLabel: toLocalTimeRangeString(restaurantShiftTimes.dinner.from, restaurantShiftTimes.dinner.to),
  };
  const allShifts: RestaurantShiftTimesOption = {
    startAt: getShiftHour(lunchFrom),
    endAt: getShiftHour(dinnerTo),
    type: restaurantShiftTimesOptionTypes.ALL_SHIFTS,
    label: formatMessage({ id: 'sales_summary.select.shift.all.day' }),
    timeRangeLabel: toLocalTimeRangeString(restaurantShiftTimes.lunch.from, restaurantShiftTimes.dinner.to),
  };

  useEffect(() => {
    setIsFetching(true);
    client.getRestaurantShiftTimes(business.id)
      .then(setRestaurantShiftTimes)
      .finally(() => setIsFetching(false));
  }, [business]);

  return {
    isFetching,
    options: [lunch, dinner, allShifts].reduce((acc: RestaurantShiftTimesOptions, shift: RestaurantShiftTimesOption) => {
      acc[shift.type] = shift;
      return acc;
    }, {} as RestaurantShiftTimesOptions),
  };
};
