import { useEffect, useState } from 'react';
import { VenueConfigurationKey } from '@sundayapp/global-configuration';
import { ConfigurationLoader } from 'src/configuration/ConfigurationLoader';
import { useAuthenticatedUserOrThrow } from 'src/auth/hooks/useAuthenticatedUserOrThrow';
import { isAuthorized } from 'src/domain/user/Role';
import navigationItems, { NavigationItem } from './NavigationItems';
import { RECONCILIATION_BASE_URL, RouteDefinitions } from '../../RouteDefinitions';
import { useGetDynamicMenuAllowed } from 'src/menu/common/queries/getDynamicMenuAllowedQuery';
import { useGlobalConfiguration } from 'src/venueconfiguration/infrastructure/useGlobalConfiguration';
import { useCurrentBusinessOrThrow } from 'src/business/hooks/useCurrentBusinessOrThrow';
import { featureFlag } from 'src/feature-flipping/infrastructure/featureFlag';
import { RewardProgramRepository } from 'src/sundayplus/loyalty/rewardProgram/infrastructure/RewardProgramRepository';
import { useAsync } from 'react-use';
import { OrderingMenuConfiguration, useOrderingMenuConfiguration } from 'src/ordering/app/navigation';
import {
  useGetReconciliationConfiguration,
} from 'src/operations/pos-reconciliation/queries/getReconciliationConfigurationQuery';
import { paymentLinkEnrollmentId } from 'src/payment-link/page/PaymentLinkPage';
import { DEMO_THE_FOUNDRY_NCR } from 'src/mocks/shouldMockDataForBusiness';
import { EnrollmentStatus, isShadowEnrollment } from 'src/business/domain/Enrollment';
import { useDebugLogger } from 'src/utils/logs/useDebugLogger';
import { useGetBusinessTippingConfiguration } from 'src/tips/queries/useGetBusinessTippingConfiguration';

const useNavigationMenu = (): NavigationItem[] => {
  const business = useCurrentBusinessOrThrow();
  const [, oapVenueConfiguration] = useOrderingMenuConfiguration();
  const debugLogger = useDebugLogger();
  const [orderingMenuConfiguration, updateOrderingMenuConfiguration] = useState<OrderingMenuConfiguration>({
    isFoodCourt: false,
    isOapWithDirectIntegration: false,
  });

  const { data: globalConfiguration } = useGlobalConfiguration();

  const reviewsEnabled = globalConfiguration?.entries.get(VenueConfigurationKey.reviewsEnabled) ?? false;

  const { data: reconciliationConfiguration } = useGetReconciliationConfiguration(business.id);
  const reconciliationEnabled = reconciliationConfiguration?.isActivated ?? false;

  const user = useAuthenticatedUserOrThrow();

  const customerDataEnabled = !!business.patEnrollment || !!business.oapEnrollment;

  const { data: dynamicMenuAllowed } = useGetDynamicMenuAllowed(business.id);
  const appConfiguration = ConfigurationLoader.load();

  const rewardProgramRepository = new RewardProgramRepository(appConfiguration.loyaltyApiBaseUrl);

  const { value: rewardProgram } = useAsync(
    async () => rewardProgramRepository.getRewardProgram(business.id),
    [business.id],
  );

  const { data: businessTippingConfiguration } = useGetBusinessTippingConfiguration(business.id);

  useEffect(() => {
    if (!business.oapEnrollment) {
      return;
    }

    oapVenueConfiguration(business.oapEnrollment, business.patEnrollment).then((venueConfiguration) => {
      updateOrderingMenuConfiguration(venueConfiguration);
    });
  }, [oapVenueConfiguration, business.oapEnrollment]);

  const allowFoodCourtRoute = (item: NavigationItem): boolean => {
    if (item.route && item.route.includes('/food-court')) {
      return orderingMenuConfiguration.isFoodCourt;
    }
    return true;
  };

  const allowDynamicMenuRoutes = (item: NavigationItem): boolean => {
    if (
      item.route &&
      (item.route.includes('/products') ||
        item.route.includes('/dynamic-menus') ||
        item.route.includes('/blocks') ||
        item.route.includes('/allergens') ||
        item.route.includes('/tags'))
    ) {
      if (orderingMenuConfiguration.isOapWithDirectIntegration) return true;
      return dynamicMenuAllowed ?? false;
    }
    return true;
  };
  const allowDynamicMenuSettings = (item: NavigationItem): boolean => {
    if (item.route && item.route.includes('/settings/menu')) {
      return dynamicMenuAllowed ?? false;
    }
    return true;
  };

  function isReconciliationReportEnabled(item: NavigationItem): boolean {
    if (item.route && item.route.includes(RECONCILIATION_BASE_URL)) {
      return reconciliationEnabled;
    }
    return true;
  }

  function isStaffPerformanceMenuEnabled(item: NavigationItem): boolean {
    if (item.route && item.route.includes('/staff-performance')) {
      if (!business.patEnrollment) return false;
      if (business.posReference) {
        return !appConfiguration.posReferencesWithoutStaffPerformance.includes(business.posReference);
      }
      return false;
    }
    return true;
  }

  function allowFeatureFlag(navigationItem: NavigationItem): boolean {
    if (navigationItem.featureFlag) {
      return featureFlag(navigationItem.featureFlag);
    }
    return true;
  }

  function hasAccessTo({ authorizedRoles, onlyForOps, route }: NavigationItem): boolean {
    if (!authorizedRoles) {
      return true;
    }
    if (onlyForOps && (user.impersonated || user.email.endsWith('@sundayapp.com'))) {
      return true;
    }

    const isRoleAuthorised = isAuthorized(user.claims, authorizedRoles, business.id) ?? false;
    if (route?.includes('/sunday-plus')) {
      const isSundayPlusEnabled = reviewsEnabled || customerDataEnabled;
      return isRoleAuthorised && isSundayPlusEnabled;
    }
    return isRoleAuthorised;
  }

  function isPaymentLinksEnabled(item: NavigationItem): boolean {
    if (item.route && item.route.includes('/payment-links')) {
      const groupeBertrandBusinessesIds = [
        'f6dfdf2e-05f4-4fb1-9b07-e7260f8458fd',
        'd3f01331-a642-4305-b036-4a31273ef814',
        '9a93ab34-e1bc-4e5f-9dbf-cf410f3e0a16',
        '950bdb87-705c-4040-b918-4079ad6ddcc8',
        'd290775e-afd6-438c-8c34-e0c912698205',
        'e9e4afd7-9ab2-4d86-a0f5-334310ef2bfc',
        '4b86a265-82d9-4518-8615-1df5cb6fcbec',
        '5c59c1d0-c97a-495b-a75a-9c02125f6b99',
        '81c7f019-7eaa-42a1-8261-055c18625b91',
        'bb6d2eae-b8ac-48b8-8c02-2ea10afe0ce2',
        '0b20ac2f-22b4-4c50-81b7-66b40570f59b',
        '2d74f998-d02a-4021-92c0-f92771bb6826',
        '7eba4779-3067-4895-8d75-2ae3bc000b88',
        '602880b4-c95d-4ac4-903c-2108f316c441',
        '7bea718c-b511-458f-89b9-be401b4a0970',
        '1ba4b2a8-0e6a-43b2-856a-0329fa81dc13',
        '74a182a7-5581-40a7-8ea9-4a7b56aa02db',
        '6076680b-98ad-41c8-873c-75340c8c1045',
        '6b822c41-2665-45a6-a22a-a7cb3a489e29',
      ];
      return !!paymentLinkEnrollmentId(business) && !groupeBertrandBusinessesIds.includes(business.id);
    }
    return true;
  }

  const isVisible = (item: NavigationItem) => !item.hiddenInDrawer;

  const isAvailableInCountry = ({ availableIn: availableCountries }: NavigationItem): boolean =>
    !availableCountries || availableCountries.some((countryCode) => business.address.countryCode === countryCode);

  function isAvailableForActiveProducts(item: NavigationItem) {
    if (item.availableTo.stillShowWhenChurned === undefined && item.subItems.length > 0) {
      return true;
    }

    return item.availableTo.products.some((service) =>
      business.enrollments.some(
        (enrollment) =>
          enrollment.product === service &&
          (enrollment.status === EnrollmentStatus.ACTIVE || item.availableTo.stillShowWhenChurned) &&
          (!item.availableTo.hideForShadowEnrollment || !isShadowEnrollment(enrollment)),
      ),
    );
  }

  const isSundayLoyaltyEnabled = (item: NavigationItem): boolean => {
    if (item.route && item.route.includes(RouteDefinitions.RewardProgram.path)) {
      return !!rewardProgram;
    }
    return true;
  };

  const isEnterpriseDashboardAvailable = (item: NavigationItem): boolean => {
    if (item.route && item.route.includes(RouteDefinitions.DataAnalyticsEnterpriseDashboard.path)) {
      return user.claims.length > 1;
    }
    return true;
  };

  const filterOutEmptyCategories = (
    route: string | undefined,
    subItems: NavigationItem[],
    subLevelItems: NavigationItem[] | undefined,
  ): boolean => {
    return !(!route && subItems.length == 0 && (subLevelItems ?? []).length == 0);
  };

  function isOPBoxStatsEnabled(item: NavigationItem) {
    if (item.route?.endsWith(RouteDefinitions.BoxStats.path)) {
      return business.name === 'La Felicità' || business.id === 'c4e77b72-816f-4eb7-9de9-adc4723c78aa';
    }
    return true;
  }

  function isPosReferenceAllowed({ hideForPos: deniedPosReferences }: NavigationItem): boolean {
    const posReference = business.posReference;
    if (!posReference || !deniedPosReferences) {
      return true;
    }
    return !deniedPosReferences.includes(posReference);
  }

  // Allow reporting page access to La Felicità and Club House 24 only
  function isReportingAllowed(item: NavigationItem) {
    if (item.route?.endsWith(RouteDefinitions.Reporting.path)) {
      return (
        business.name === 'La Felicità' ||
        business.id === 'c4e77b72-816f-4eb7-9de9-adc4723c78aa' ||
        business.id === 'acac904e-aa3d-453e-abd8-92bc2b57d920'
      );
    }
    return true;
  }

  function isVisibleOnDemo(item: NavigationItem) {
    // Hide payment terminal page for Foundry NCR
    return !(item.route?.endsWith(RouteDefinitions.PaymentTerminal.path) && business.id === DEMO_THE_FOUNDRY_NCR);
  }

  function isOnlyOnSpecificBusinessIds(item: NavigationItem) {
    if (item.onlyOnSpecificBusinessIds) {
      return item.onlyOnSpecificBusinessIds.includes(business.id);
    }
    return true;
  }

  function areSundayTippingPagesAvailable(item: NavigationItem) {
    const staffRules: string = 'sunday_tipping.staff_rules.title';
    const collection: string = 'sunday_tipping.collection.title';
    const distribution: string = 'sunday_tipping.distribution.title';
    const allTipsPages = [collection, distribution, staffRules];

    if (!allTipsPages.includes(item.text)) {
      return true;
    }

    if (!businessTippingConfiguration) {
      return false;
    }

    // manual distribution is done - do not want double payout
    const kodawariBusinesses = ['61bca64a-621a-43c8-ad6d-a2d279cd66c0', 'c70895b6-42d4-4ea5-a348-16d3c6ec9c9a'];
    if (kodawariBusinesses.includes(business.id) && item.text === distribution) {
      return false;
    }

    switch (businessTippingConfiguration.type) {
      case 'PosPooled':
        return [collection, distribution].includes(item.text);
      case 'SundayTipping':
        return [collection, distribution, staffRules].includes(item.text);
      case 'POC':
        const instantTippingEnabled = [
          businessTippingConfiguration.pat?.type,
          businessTippingConfiguration.pdq?.type,
        ].includes('DirectTipping');
        return [collection, distribution].concat(instantTippingEnabled ? [staffRules] : []).includes(item.text);
    }
  }

  function isClawbacksPageAvailable(item: NavigationItem) {
    if (item.text === 'accounting.clawbacks_tab') {
      const sundayTippingEnabled = businessTippingConfiguration?.type === 'SundayTipping';
      const tippingPocEnabled = businessTippingConfiguration?.type === 'POC';
      return sundayTippingEnabled || tippingPocEnabled;
    }
    return true;
  }

  const navigationFilters: Record<string, (item: NavigationItem) => boolean> = {
    'has access to': hasAccessTo,
    'is available for product': isAvailableForActiveProducts,
    'is available in country': isAvailableInCountry,
    'is visible': isVisible,
    'is staff performance menu enabled': isStaffPerformanceMenuEnabled,
    'allow food court route': allowFoodCourtRoute,
    'is reconciliation report enabled': isReconciliationReportEnabled,
    'allow dynamic menu routes': allowDynamicMenuRoutes,
    'allow dynamic menu settings': allowDynamicMenuSettings,
    'is payment links enabled': isPaymentLinksEnabled,
    'is sunday loyalty enabled': isSundayLoyaltyEnabled,
    'is enterprise dashboard available': isEnterpriseDashboardAvailable,
    'is OP box stats enabled': isOPBoxStatsEnabled,
    'allow feature flag': allowFeatureFlag,
    'is reporting allowed': isReportingAllowed,
    'is visible on demo': isVisibleOnDemo,
    'is pos reference allowed': isPosReferenceAllowed,
    'only on specific business ids': isOnlyOnSpecificBusinessIds,
    'sunday tipping pages': areSundayTippingPagesAvailable,
    'clawbacks': isClawbacksPageAvailable,
  };

  function allFiltersWithLog(item: NavigationItem): boolean {
    for (const [key, filter] of Object.entries(navigationFilters)) {
      if (!filter(item)) {
        debugLogger.log(`Navigation item '${item.text}' filtered out by '${key}'`, item);
        return false;
      }
    }
    return true;
  }

  const filterItems = (items: NavigationItem[]): NavigationItem[] =>
    items
      .filter(allFiltersWithLog)
      .map((item) => ({
        ...item,
        subItems: filterItems(item.subItems),
        subLevelItems: filterItems(item.subLevelItems ?? []),
      }))
      .filter(({ route, subItems, subLevelItems }) => filterOutEmptyCategories(route, subItems, subLevelItems));

  return filterItems(navigationItems(business.id).items);
};

export default useNavigationMenu;
