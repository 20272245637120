import React from 'react';
import styled from 'styled-components';
import { ArrowIcon, colorUsage, IconWrapper, spaceUsages, Title } from '@sundayapp/b2b-react-component-library';
import { useRouting } from 'src/ordering/common/hooks';
import MenuLastEditionDate from './MenuLastEditionDate';
import MenuEnabler from './MenuEnabler';
import { Menu } from '../../../domain/Menu';
import { BusinessId } from 'src/business/domain/Business';
import { SourceIcon } from 'src/menu/common/SourceIcon';

type MenuTitleProps = {
  menu: Menu;
  businessId: BusinessId;
};

const MenuHeaderWrapper = styled.div`
  display: flex;
  flex-direction: row;
  position: relative;
  padding-left: ${spaceUsages.mediumLarge};
  padding-right: ${spaceUsages.mediumLarge};
  justify-content: start;
`;

const GoBackWrapper = styled.div`
  left: -16px;
  position: absolute;
  margin-top: 16px;
`;

const MenuDetails = styled.div`
  display: flex;
  flex-direction: column;
`;

const MenuTitle = styled.div`
  display: flex;
  flex-direction: row;
  align-items: baseline;
  gap: 8px;
`;

const MenuHeader = ({
  businessId, menu,
}: MenuTitleProps) => {
  const menuNavigation = useRouting();

  const onGoToMenu = async () => {
    menuNavigation.goToMenus(businessId);
  };

  return (
    <MenuHeaderWrapper>
      <GoBackWrapper>
        <IconWrapper size="small" cursor="pointer" color={colorUsage.secondaryText} onClick={onGoToMenu}>
          <ArrowIcon direction="left" />
        </IconWrapper>
      </GoBackWrapper>

      <MenuDetails>
        <MenuTitle>
          <SourceIcon isSunday={menu.sundayMenu} />
          <Title size="title2" pr={spaceUsages.xLarge}>
            {menu?.name}
          </Title>
        </MenuTitle>
        <MenuLastEditionDate menu={menu} />
      </MenuDetails>
      <MenuEnabler menu={menu} businessId={businessId} />
    </MenuHeaderWrapper>
  );
};

export default MenuHeader;
