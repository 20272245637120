export class Configuration {
  constructor(
    public frontendUrl: string,
    public callbackUrl: string,
    public paymentApiBaseUrl: string,
    public venueApiBaseUrl: string,
    public merchantAccountsBaseUrl: string,
    public patOrchestratorUrl: string,
    public vposApiBaseUrl: string,
    public vposRefreshBillApiBaseUrl: string,
    public b2cAppBaseUrl: string,
    public waiterwebAppBaseUrl: string,
    public datadogToken: string | undefined,
    public canPushToDatadog: boolean,
    public isDevelopment: boolean,
    public envName: string | undefined,
    public venueFirebaseConfig: any,
    public version: string,
    public datadogRumApplicationId: string | undefined,
    public datadogRumToken: string | undefined,
    public intercomAppId: string,
    public segmentToken: string,
    public globalConfigurationUrl: string,
    public venueFeedbackBaseUrl: string,
    public receiptApiBaseUrl: string,
    public userRoleHintUrl: string,
    public englishReviewHintUrl: string,
    public orderingMerchantDashboardUrl: string,
    public consentManagerBaseUrl: string,
    public menuApiBaseUrl: string,
    public orderingApiBaseUrl: string,
    public featureFlagRedesign: boolean,
    public accountingApiBaseUrl: string,
    public bookkeepingApiBaseUrl: string,
    public contactUsUrl: string,
    public flagsmithEnvId: string,
    public googleOauth2ClientId: string,
    public neptingApiBaseUrl: string,
    public loyaltyApiBaseUrl: string,
    public frenchReviewHintUrl: string,
    public paymentTerminalBaseUrl: string,
    public reorderQRCodesFRUrl: string,
    public reorderQRCodesUSUrl: string,
    public reorderQRCodesUKUrl: string,
    public referralFormFRUrl: string,
    public referralFormUSUrl: string,
    public referralFormUKUrl: string,
    public referralFormDEUrl: string,
    public reconciliationApiBaseUrl: string,
    public merchantAnalyticsUrl: string,
    public venueReferentialUrl: string,
    public auth0ClientId: string,
    public auth0Domain: string,
    public auth0Audience: string,
    public vposVenueUrl: string,
    public experimentDeploymentKey: string,
    public posReferencesWithoutStaffPerformance: string[],
    public waiterAppBackendUrl: string,
    public metabaseUrl: string,
    public sundayDeviceManagerUrl: string,
    public pongoBaseUrl: string,
    public pongoClientId: string,
    public tipsSuggestionUrl: string,
    public auditTrailApiBaseUrl: string,
    public ogEnrollmentId: string | undefined,
    public enableHybridOrderingAreas: boolean,
    public enablePosProductModifications: boolean,
    public businessIdsNotSupportingMenuPreview: string[],
  ) {
  }
}
