import { Configuration } from './Configuration';
import { JavascriptBasedVariables } from './JavascriptBasedVariables';
import { EnvBasedVariables } from './EnvBasedVariables';
import { prependSchemeIfNotAlreadyPresent } from '../utils/UrlUtils';
import { EnrollmentId } from 'src/business/domain/Enrollment';

export class ConfigurationLoader {
  public static env = JavascriptBasedVariables.isJavascriptEnvDefined()
    ? new JavascriptBasedVariables()
    : new EnvBasedVariables();

  static load(): Configuration {
    return new Configuration(
      this.frontendUrl(),
      this.callbackUrl(),
      this.paymentApiBaseUrl(),
      this.venueApiBaseUrl(),
      this.merchantAccountsBaseUrl(),
      this.patOrchestratorUrl(),
      this.vposApiBaseUrl(),
      this.vposRefreshBillApiBaseUrl(),
      this.b2cAppBaseUrl(),
      this.waiterwebAppBaseUrl(),
      this.datadogToken(),
      this.datadogToken() !== undefined,
      this.isDevelopment(),
      this.envName(),
      this.venueFirebaseConfig(),
      this.version(),
      this.datadogRumApplicationId(),
      this.datadogRumToken(),
      this.intercomAppId(),
      this.segmentToken(),
      this.globalConfigurationUrl(),
      this.venueFeedbackBaseUrl(),
      this.receiptApiBaseUrl(),
      this.userRoleHintUrl(),
      this.englishReviewHintUrl(),
      this.orderingMerchantDashboardUrl(),
      this.consentManagerBaseUrl(),
      this.menuApiBaseUrl(),
      this.orderingApiBaseUrl(),
      this.featureFlagRedesign(),
      this.accountingApiBaseUrl(),
      this.bookkeepingApiBaseUrl(),
      this.contactUsUrl(),
      this.flagsmithClientId(),
      this.googleOauth2ClientId(),
      this.neptingApiUrl(),
      this.loyaltyBaseUrl(),
      this.frenchReviewHintUrl(),
      this.paymentTerminalBackendUrl(),
      this.reorderQRCodesFRUrl(),
      this.reorderQRCodesUSUrl(),
      this.reorderQRCodesUKUrl(),
      this.referralFormFRUrl(),
      this.referralFormUSUrl(),
      this.referralFormUKUrl(),
      this.referralFormDEUrl(),
      this.reconciliationApiBaseUrl(),
      this.merchantAnalyticsUrl(),
      this.venueReferentialUrl(),
      this.auth0ClientId(),
      this.auth0Domain(),
      this.auth0Audience(),
      this.vposVenueUrl(),
      this.experimentDeploymentKey(),
      this.posReferencesWithoutStaffPerformance(),
      this.waiterAppBackendBaseUrl(),
      this.metabaseUrl(),
      this.sundayDeviceManagerUrl(),
      this.pongoBaseUrl(),
      this.pongoClientId(),
      this.tipsSuggestionUrl(),
      this.auditTrailApiBaseUrl(),
      this.ogEnrollmentId(),
      this.enableHybridOrderingAreas(),
      this.enablePosProductModifications(),
      this.businessIdsNotSupportingMenuPreview(),
    );
  }

  private static isDevelopment() {
    return this.env.VITE_ENV === 'development';
  }

  private static scheme() {
    return this.env.VITE_ENV === 'development' || this.env.VITE_ENV === 'test' ? 'http' : 'https';
  }

  private static version(): string {
    return this.env.VITE_APPLICATION_VERSION!;
  }

  private static paymentApiBaseUrl(): string {
    return `${this.scheme()}://${this.env.VITE_PAYMENT_URL}`;
  }

  private static venueApiBaseUrl(): string {
    return `${this.scheme()}://${this.env.VITE_VENUE_REFERENTIAL_URL}`;
  }

  private static frontendUrl(): string {
    return `${this.scheme()}://${this.env.VITE_FRONT_URL}:${this.env.VITE_FRONT_PORT}`;
  }

  private static callbackUrl(): string {
    if (this.env.VITE_FRONT_URL === 'localhost') {
      return `http://${this.env.VITE_FRONT_URL}:${this.env.VITE_FRONT_PORT}`;
    }
    return `https://${this.env.VITE_FRONT_URL}`;
  }

  private static vposApiBaseUrl(): string {
    return `${this.scheme()}://${this.env.VITE_VPOS_URL}`;
  }

  private static vposRefreshBillApiBaseUrl(): string {
    return `${this.scheme()}://${this.env.VITE_VPOS_REFRESH_BILL_URL}`;
  }

  private static receiptApiBaseUrl(): string {
    return `${this.scheme()}://${this.env.VITE_RECEIPT_URL}`;
  }

  private static userRoleHintUrl(): string {
    return this.env.VITE_USER_ROLE_HINT_URL;
  }

  private static englishReviewHintUrl(): string {
    return this.env.VITE_REVIEW_HINT_URL_EN;
  }

  private static b2cAppBaseUrl(): string {
    return `${this.scheme()}://${this.env.VITE_HOSTNAME_B2C_WEB_APP}`;
  }

  private static waiterwebAppBaseUrl(): string {
    return `${this.scheme()}://${this.env.VITE_HOSTNAME_WAITER_WEB_APP}`;
  }

  private static datadogToken() {
    return this.env.VITE_DATADOG_CLIENT_TOKEN;
  }

  private static envName(): string | undefined {
    return this.env.VITE_ENV_NAME;
  }

  private static venueFirebaseConfig() {
    return JSON.parse(this.env.VITE_VENUE_FIREBASE_CONFIG!);
  }

  private static datadogRumApplicationId(): string | undefined {
    return this.env.VITE_DATADOG_APPLICATION_ID;
  }

  private static datadogRumToken(): string | undefined {
    return this.env.VITE_DATADOG_RUM_TOKEN;
  }

  private static intercomAppId(): string {
    return this.env.VITE_INTERCOM_ID ?? '';
  }

  private static segmentToken() {
    return this.env.VITE_SEGMENT_TOKEN ?? '';
  }

  private static globalConfigurationUrl(): string {
    return `${this.scheme()}://${this.env.VITE_GLOBAL_CONFIGURATION_URL}`;
  }

  private static venueFeedbackBaseUrl(): string {
    return `${this.scheme()}://${this.env.VITE_VENUE_FEEDBACK_URL}`;
  }

  private static loyaltyBaseUrl(): string {
    return `${this.scheme()}://${this.env.VITE_LOYALTY_BACKEND_URL}`;
  }

  private static consentManagerBaseUrl(): string {
    return `${this.scheme()}://${this.env.VITE_CONSENT_MANAGER_URL}`;
  }

  private static orderingMerchantDashboardUrl(): string {
    return `${this.scheme()}://${this.env.VITE_ORDERING_MERCHANT_DASHBOARD_HOSTNAME}`;
  }

  private static menuApiBaseUrl(): string {
    return `${this.scheme()}://${this.env.VITE_MENU_URL}`;
  }

  private static orderingApiBaseUrl(): string {
    return `${this.scheme()}://${this.env.VITE_ORDERING_URL}`;
  }

  private static featureFlagRedesign(): boolean {
    const params = new URL(document.location.href).searchParams;
    return params.get('redesign') === 'true';
  }

  private static accountingApiBaseUrl(): string {
    return `${this.scheme()}://${this.env.VITE_ACCOUNTING_URL}`;
  }

  private static bookkeepingApiBaseUrl(): string {
    return `${this.scheme()}://${this.env.VITE_BOOKKEEPING_URL}`;
  }

  private static contactUsUrl(): string {
    return this.env.VITE_CONTACT_US_URL;
  }

  private static merchantAccountsBaseUrl() {
    return `${this.scheme()}://${this.env.VITE_MERCHANT_ACCOUNTS_URL}`;
  }

  private static flagsmithClientId() {
    return this.env.VITE_FLAGSMITH_ENV_ID;
  }

  private static googleOauth2ClientId() {
    return this.env.VITE_GOOGLE_OAUTH2_CLIENT_ID;
  }

  private static patOrchestratorUrl() {
    return `${this.scheme()}://${this.env.VITE_PAT_ORCHESTRATOR_URL}`;
  }

  private static neptingApiUrl() {
    return `${this.scheme()}://${this.env.VITE_NEPTING_URL}`;
  }

  private static frenchReviewHintUrl(): string {
    return this.env.VITE_REVIEW_HINT_URL_FR;
  }

  private static paymentTerminalBackendUrl() {
    return `${this.scheme()}://${this.env.VITE_PAYMENT_TERMINAL_BACKEND_URL}`;
  }

  private static reorderQRCodesFRUrl() {
    return `${this.env.VITE_REORDER_QR_CODES_FR_URL}`;
  }

  private static reorderQRCodesUSUrl() {
    return `${this.env.VITE_REORDER_QR_CODES_US_URL}`;
  }

  private static reorderQRCodesUKUrl() {
    return `${this.env.VITE_REORDER_QR_CODES_UK_URL}`;
  }

  private static referralFormFRUrl() {
    return `${this.env.VITE_REFERRAL_REDIRECT_FR_URL}`;
  }

  private static referralFormUSUrl() {
    return `${this.env.VITE_REFERRAL_REDIRECT_US_URL}`;
  }

  private static referralFormUKUrl() {
    return `${this.env.VITE_REFERRAL_REDIRECT_UK_URL}`;
  }

  private static referralFormDEUrl() {
    return `${this.env.VITE_REFERRAL_REDIRECT_DE_URL}`;
  }

  private static reconciliationApiBaseUrl() {
    return prependSchemeIfNotAlreadyPresent(this.scheme(), this.env.VITE_RECONCILIATION_API_URL);
  }

  private static merchantAnalyticsUrl() {
    return prependSchemeIfNotAlreadyPresent(this.scheme(), this.env.VITE_MERCHANT_ANALYTICS_API_URL);
  }

  private static auth0ClientId() {
    return `${this.env.VITE_AUTH0_CLIENT_ID}`;
  }

  private static auth0Domain() {
    return `${this.env.VITE_AUTH0_DOMAIN}`;
  }

  private static auth0Audience() {
    return `${this.env.VITE_AUTH0_AUDIENCE}`;
  }

  private static venueReferentialUrl(): string {
    return `${this.scheme()}://${this.env.VITE_VENUE_REFERENTIAL_URL}`;
  }

  private static vposVenueUrl(): string {
    return `${this.scheme()}://${this.env.VITE_VPOS_VENUE_URL}`;
  }

  private static experimentDeploymentKey() {
    return `${this.env.VITE_EXPERIMENT_DEPLOYMENT_KEY}`;
  }

  private static posReferencesWithoutStaffPerformance() {
    const posReferences = this.env.VITE_POS_REFERENCES_WITHOUT_STAFF_PERFORMANCE;
    if (posReferences) {
      return posReferences.split(',');
    }
    return [];
  }

  private static waiterAppBackendBaseUrl(): string {
    return `${this.scheme()}://${this.env.VITE_HOSTNAME_WAITER_APP_BACKEND}`;
  }

  private static metabaseUrl(): string {
    return this.env.VITE_EMBEDDED_METABASE_URL;
  }

  private static sundayDeviceManagerUrl(): string {
    return `${this.scheme()}://${this.env.VITE_SUNDAY_DEVICE_MANAGER_URL}`;
  }

  private static pongoBaseUrl() {
    return this.env.VITE_PONGO_BASE_URL;
  }

  private static pongoClientId() {
    return this.env.VITE_PONGO_CLIENT_ID;
  }

  private static bourdoncleBusinessesIds() {
    const businessesIds = this.env.VITE_BOURDONCLE_BUSINESSES_IDS;
    if (businessesIds) {
      return businessesIds.split(',');
    }
    return [];
  }

  private static tipsSuggestionUrl(): string {
    return `${this.scheme()}://${this.env.VITE_TIPS_SUGGESTION_URL}`;
  }

  private static auditTrailApiBaseUrl(): string {
    return `${this.scheme()}://${this.env.VITE_API_GATEWAY_URL}${this.env.VITE_AUDIT_TRAIL}`;
  }

  private static ogEnrollmentId(): EnrollmentId | undefined {
    return this.env.VITE_OG_ENROLLMENT_ID;
  }

  private static enableHybridOrderingAreas(): boolean {
    return (this.env.VITE_ENABLE_HYBRID_ORDERING_AREAS ?? '').toLowerCase() === 'true';
  }

  private static enablePosProductModifications(): boolean {
    return (this.env.VITE_ENABLE_POS_PRODUCT_MODIFICATIONS ?? '').toLowerCase() === 'true';
  }

  private static businessIdsNotSupportingMenuPreview(): string[] {
    return (this.env.VITE_BUSINESS_IDS_NOT_SUPPORTING_MENU_PREVIEW ?? '')
      .split(',')
      .map((id: string) => id.trim().toLowerCase());
  }
}
