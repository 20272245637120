import { Page } from '@templates/PageTemplate';
import React from 'react';

export const MultiLocationsInvoices = () => {
  return (
    <Page
      hasHeadTitle={{
        preLogoText: [
          {
            id: undefined,
          },
        ],
        postLogoText: [
          {
            id: 'sunday',
          },
        ],
        variant: 'h5',
        hasBadge: false,
        badgeLabel: '',
        showTitle: false,
      }}
      noBanner={true}
    >
      <h1>Invoices</h1>
    </Page>
  );
};
