import { generatePath } from 'react-router-dom';
import { RouteDefinitions } from '../RouteDefinitions';
import { BusinessId } from 'src/business/domain/Business';

export const venuePath = (venueId: string): string => generatePath(`/${RouteDefinitions.Venue.path}`, { venueId });

export const businessStaticMenuPath = (businessId: BusinessId, menuId: string) =>
  generatePath(`/${RouteDefinitions.Venue.path}${RouteDefinitions.StaticMenuDetails.path}`, {
    venueId: businessId,
    menuId,
  });

export const venueDynamicMenuPath = (businessId: BusinessId, menuId: string) =>
  generatePath(`/${RouteDefinitions.Venue.path}${RouteDefinitions.DigitalMenuDetails.path}`, {
    venueId: businessId,
    menuId,
  });

export const businessStaticMenusPath = (venueId: string): string =>
  generatePath(`/${RouteDefinitions.Venue.path}${RouteDefinitions.StaticMenus.path}`, { venueId });

export const venueDynamicMenusPath = (businessId: BusinessId): string =>
  generatePath(`/${RouteDefinitions.Venue.path}${RouteDefinitions.DigitalMenus.path}`, { venueId: businessId });

export const venueProductsPath = (venueId: string) =>
  generatePath(`/${RouteDefinitions.Venue.path}${RouteDefinitions.Products.path}`, { venueId });

export const venuePaymentsPath = (businessId: BusinessId): string =>
  generatePath(`/${RouteDefinitions.Venue.path}${RouteDefinitions.Payments.path}`, { venueId: businessId });

export const venueSpecificPaymentPath = (businessId: BusinessId, paymentDate: Date): string => {
  const localDate = new Date(paymentDate.getTime() - paymentDate.getTimezoneOffset() * 60000);
  const formattedDate = localDate.toISOString().slice(0, 16); // YYYY-MM-DDThh:mm
  const encodedDate = encodeURIComponent(formattedDate);
  return generatePath(`/${RouteDefinitions.Venue.path}${RouteDefinitions.Payments.path}?preset=CUSTOM&startDate=${encodedDate}&endDate=${encodedDate}`, { venueId: businessId });
};

export const venueMenuProductPath = (venueId: string, productId: string): string =>
  generatePath(`/${RouteDefinitions.Venue.path}${RouteDefinitions.ProductDetails.path}`, { venueId, productId });

export const venueMenuBlockPath = (venueId: string, blockId: string): string =>
  generatePath(`/${RouteDefinitions.Venue.path}${RouteDefinitions.BlocksDetails.path}`, { venueId, blockId });

export const venueSettingsAreasPath = (businessId: BusinessId) =>
  generatePath(`/${RouteDefinitions.Venue.path}${RouteDefinitions.areasSettings.path}`, { venueId: businessId });

export const venueSettingsAreasNewPath = (businessId: BusinessId, boxId: string) =>
  generatePath(`/${RouteDefinitions.Venue.path}${RouteDefinitions.areaCreateSettings.path}`, {
    venueId: businessId,
    boxId,
  });

export const venueSettingsAreaPath = (businessId: BusinessId, boxId: string, areaId: string) =>
  generatePath(`/${RouteDefinitions.Venue.path}${RouteDefinitions.areaDetailsSettings.path}`, {
    venueId: businessId,
    boxId,
    areaId,
  });

export const venueRevenuesPath = (venueId: string, preset: string): string =>
  generatePath(`/${RouteDefinitions.Venue.path}${RouteDefinitions.Revenues.path}?preset=${preset}`, { venueId });

export const tipsPromotionPath = (venueId: string): string =>
  generatePath(`/${RouteDefinitions.Venue.path}${RouteDefinitions.TipsPromotion.path}`, { venueId });

export const tipsDistributionPath = (venueId: string): string =>
  generatePath(`/${RouteDefinitions.Venue.path}${RouteDefinitions.TipsDistribution.path}`, { venueId });

export const tipsCollectionPath = (venueId: string): string =>
  generatePath(`/${RouteDefinitions.Venue.path}${RouteDefinitions.TipsCollection.path}`, { venueId });

export const tipsStaffRulesPath = (venueId: string): string =>
  generatePath(`/${RouteDefinitions.Venue.path}${RouteDefinitions.TipsStaffRules.path}`, { venueId });

export const waiterHistoryPath = (venueId: string, waiterId: string): string =>
  generatePath(`/${RouteDefinitions.Venue.path}${RouteDefinitions.WaiterHistoryById.path}`, { venueId, waiterId });

export const waiterTippingPath = (venueId: string, waiterId: string): string =>
  generatePath(`/${RouteDefinitions.Venue.path}${RouteDefinitions.WaiterTippingById.path}`, { venueId, waiterId });

export const venueSettingsInformationPath = (venueId: string): string =>
  generatePath(`/${RouteDefinitions.Venue.path}${RouteDefinitions.VenueInformation.path}`, { venueId });

export const venueSettingsPaymentsPath = (venueId: string): string =>
  generatePath(`/${RouteDefinitions.Venue.path}${RouteDefinitions.PaymentsSettings.path}`, { venueId });
export const venueSettingsRevenueCentersPath = (venueId: string): string =>
  generatePath(`/${RouteDefinitions.Venue.path}${RouteDefinitions.RevenueCentersSettings.path}`, { venueId });
export const venueSettingsReconciliationPath = (venueId: string): string =>
  generatePath(`/${RouteDefinitions.Venue.path}${RouteDefinitions.ReconciliationSettings.path}`, { venueId });

export const venueSettingsShiftPath = (businessId: BusinessId): string =>
  generatePath(`/${RouteDefinitions.Venue.path}${RouteDefinitions.ShiftSettings.path}`, { venueId: businessId });

export const settingsReviewsPath = (businessId: BusinessId): string =>
  generatePath(`/${RouteDefinitions.Venue.path}${RouteDefinitions.ReviewsSettings.path}`, { venueId: businessId });
export const settingsMenusPath = (businessId: BusinessId): string =>
  generatePath(`/${RouteDefinitions.Venue.path}${RouteDefinitions.MenuSettings.path}`, { venueId: businessId });
export const settingsPermissionsPath = (businessId: BusinessId): string =>
  generatePath(`/${RouteDefinitions.Venue.path}${RouteDefinitions.UserPermissions.path}`, { venueId: businessId });
export const settingsConfigurationReplicationPath = (businessId: BusinessId): string =>
  generatePath(`/${RouteDefinitions.Venue.path}${RouteDefinitions.ConfigurationReplication.path}`, {
    venueId: businessId,
  });
export const settingsAppSettingsPath = (businessId: BusinessId): string =>
  generatePath(`/${RouteDefinitions.Venue.path}${RouteDefinitions.AppSettings.path}`, { venueId: businessId });

export const settingsOrderingPath = (businessId: BusinessId): string =>
  generatePath(`/${RouteDefinitions.Venue.path}${RouteDefinitions.OrderingSettings.path}`, { venueId: businessId });
export const settingsOrderingAppPath = (businessId: BusinessId): string =>
  generatePath(`/${RouteDefinitions.Venue.path}${RouteDefinitions.OrderingAppSettings.path}`, { venueId: businessId });
export const settingsCustomizationPath = (businessId: BusinessId): string =>
  generatePath(`/${RouteDefinitions.Venue.path}${RouteDefinitions.Customization.path}`, { venueId: businessId });

export const venueStaffPerformancePath = (venueId: string): string =>
  generatePath(`/${RouteDefinitions.Venue.path}${RouteDefinitions.StaffPerformance.path}`, { venueId });
export const venueHomePath = (venueId: string): string =>
  generatePath(`/${RouteDefinitions.Venue.path}${RouteDefinitions.Home.path}`, { venueId });
export const communicationPreferencesPath = (venueId: string): string =>
  generatePath(`/${RouteDefinitions.Venue.path}${RouteDefinitions.CommunicationPreferences.path}`, { venueId });

export const venueFoodCourtPath = (venueId: string): string =>
  generatePath(`/${RouteDefinitions.Venue.path}${RouteDefinitions.FoodCourt.path}`, { venueId });

export const boxesStatPath = (venueId: string): string =>
  generatePath(`/${RouteDefinitions.Venue.path}${RouteDefinitions.BoxStats.path}`, { venueId });

export const venueBookingsPath = (venueId: string): string =>
  generatePath(`/${RouteDefinitions.Venue.path}${RouteDefinitions.Bookings.path}`, { venueId });

export const venueKdsPath = (venueId: string): string =>
  generatePath(`/${RouteDefinitions.Venue.path}${RouteDefinitions.Kds.path}`, { venueId });

export const venueVouchersVivatechPath = (venueId: string): string =>
  generatePath(`/${RouteDefinitions.Venue.path}${RouteDefinitions.VouchersClubHouse24.path}`, { venueId });

export const sundayForRestaurantsPath = (venueId: string): string =>
  generatePath(`/${RouteDefinitions.Venue.path}${RouteDefinitions.SundayForRestaurants.path}`, { venueId });

export const venueTpePath = (venueId: string): string =>
  generatePath(`/${RouteDefinitions.Venue.path}${RouteDefinitions.PaymentTerminal.path}`, { venueId });

export const venueAccountingPath = (venueId: string): string =>
  generatePath(`/${RouteDefinitions.Venue.path}${RouteDefinitions.Accounting.path}`, { venueId });

export const venueAccountingInvoicesPath = (venueId: string): string =>
  generatePath(`/${RouteDefinitions.Venue.path}${RouteDefinitions.Invoices.path}`, { venueId });

export const businessAccountingClawbacksPath = (venueId: string): string =>
  generatePath(`/${RouteDefinitions.Venue.path}${RouteDefinitions.Clawbacks.path}`, { venueId });

export const venueReconciliationTabPath = (businessId: BusinessId, reconciliationTab: string): string =>
  generatePath(`/${RouteDefinitions.Venue.path}${RouteDefinitions.ReconciliationTabs.path}`, {
    venueId: businessId,
    reconciliationTab,
  });
export const venueReconciliationOpenTabPath = (businessId: BusinessId): string =>
  venueReconciliationTabPath(businessId, 'open');

export const venueReconciliationHistoryTabPath = (businessId: BusinessId): string =>
  venueReconciliationTabPath(businessId, 'history');

export const venueReconciliationReportPath = (businessId: BusinessId, reportId: string): string =>
  generatePath(`/${RouteDefinitions.Venue.path}${RouteDefinitions.ReconciliationReportById.path}`, {
    venueId: businessId,
    reportId,
  });

export const venueBillsPath = (venueId: string): string =>
  generatePath(`/${RouteDefinitions.Venue.path}${RouteDefinitions.Bills.path}`, { venueId });

export const venueOPBillsPath = (venueId: string): string =>
  generatePath(`/${RouteDefinitions.Venue.path}${RouteDefinitions.OPOrders.path}`, { venueId });

export const venueQRCodesPath = (venueId: string): string =>
  generatePath(`/${RouteDefinitions.Venue.path}${RouteDefinitions.QRCodes.path}`, { venueId });

export const venueSundayPlusInsightsPath = (venueId: string): string =>
  generatePath(`/${RouteDefinitions.Venue.path}${RouteDefinitions.ReviewsInsights.path}`, { venueId });
export const venueSundayPlusGuestExperiencePath = (venueId: string): string =>
  generatePath(`/${RouteDefinitions.Venue.path}${RouteDefinitions.ReviewsGuestExperience.path}`, { venueId });

export const venueSundayPlusReviewsPath = (venueId: string): string =>
  generatePath(`/${RouteDefinitions.Venue.path}${RouteDefinitions.Reviews.path}`, { venueId });

export const venueSundayPlusReplyToReviewsPath = (venueId: string): string =>
  generatePath(`/${RouteDefinitions.Venue.path}${RouteDefinitions.ReplyToReviews.path}`, { venueId });

export const venueSundayPlusRewardProgramPath = (venueId: string): string =>
  generatePath(`/${RouteDefinitions.Venue.path}${RouteDefinitions.RewardProgram.path}`, { venueId });

export const venueSundayPlusCustomerDataPath = (venueId: string): string =>
  generatePath(`/${RouteDefinitions.Venue.path}${RouteDefinitions.CustomerData.path}`, { venueId });

export const venueStripeSetupDonePath = (venueId: string): string =>
  generatePath(`/${RouteDefinitions.Venue.path}${RouteDefinitions.StripeSetupDone.path}`, { venueId });

export const venuePaymentLinksPath = (venueId: string): string =>
  generatePath(`/${RouteDefinitions.Venue.path}${RouteDefinitions.PaymentLinks.path}`, { venueId });

export const venueOPVoucherCodesPath = (venueId: string): string =>
  generatePath(`/${RouteDefinitions.Venue.path}${RouteDefinitions.OPVoucherCodes.path}`, { venueId });

export const venueOPProductAvailabilityPath = (venueId: string): string =>
  generatePath(`/${RouteDefinitions.Venue.path}${RouteDefinitions.OPProductAvailability.path}`, { venueId });

export const venueEndOfServicePath = (venueId: string): string =>
  generatePath(`/${RouteDefinitions.Venue.path}${RouteDefinitions.EndOfService.path}`, { venueId });

export const venueDataAnalyticsOverviewPath = (venueId: string): string =>
  generatePath(`/${RouteDefinitions.Venue.path}${RouteDefinitions.DataAnalyticsOverview.path}`, { venueId });

export const venueDataAnalyticsBusinessPerformancePath = (venueId: string): string =>
  generatePath(`/${RouteDefinitions.Venue.path}${RouteDefinitions.DataAnalyticsBusinessPerformance.path}`, { venueId });

export const venueDataAnalyticsCustomerInsightsPath = (venueId: string): string =>
  generatePath(`/${RouteDefinitions.Venue.path}${RouteDefinitions.DataAnalyticsCustomerInsights.path}`, { venueId });

export const venueDataAnalyticsTipsPath = (venueId: string): string =>
  generatePath(`/${RouteDefinitions.Venue.path}${RouteDefinitions.DataAnalyticsTips.path}`, { venueId });

export const venueDataAnalyticsRepeatKpisPath = (venueId: string): string =>
  generatePath(`/${RouteDefinitions.Venue.path}${RouteDefinitions.DataAnalyticsRepeatKpis.path}`, { venueId });

export const venueDataAnalyticsEnterpriseDashboardPath = (venueId: string): string =>
  generatePath(`/${RouteDefinitions.Venue.path}${RouteDefinitions.DataAnalyticsEnterpriseDashboard.path}`, { venueId });

export const opsToolingPath = (businessId: string): string =>
  generatePath(`/${RouteDefinitions.Venue.path}${RouteDefinitions.OpsTooling.path}`, { venueId: businessId });

export const opsToolingTipsSettingsPath = (businessId: string): string =>
  generatePath(`/${RouteDefinitions.Venue.path}${RouteDefinitions.TipsSetting.path}`, { venueId: businessId });

export const opsToolingPatSettingsPath = (businessId: string): string =>
  generatePath(`/${RouteDefinitions.Venue.path}${RouteDefinitions.PatSettings.path}`, { venueId: businessId });

export const opsToolingEnterpriseSettingsPath = (businessId: string): string =>
  generatePath(`/${RouteDefinitions.Venue.path}${RouteDefinitions.EnterpriseSettings.path}`, { venueId: businessId });

export const opsToolingLoyaltyOnboardingSettingsPath = (businessId: string): string =>
  generatePath(`/${RouteDefinitions.Venue.path}${RouteDefinitions.LoyaltyOnboarding.path}`, { venueId: businessId });

export const opsToolingPosPaymentsSettingsPath = (businessId: string): string =>
  generatePath(`/${RouteDefinitions.Venue.path}${RouteDefinitions.PosPaymentSettings.path}`, { venueId: businessId });

export const checkoutMigration = (businessId: string): string =>
  generatePath(`/${RouteDefinitions.Venue.path}${RouteDefinitions.PaymentsCheckoutOnboarding.path}`, {
    venueId: businessId,
  });

export const venueAccountingRevenuesPath = (venueId: string): string =>
  generatePath(`/${RouteDefinitions.Venue.path}${RouteDefinitions.Revenues.path}`, { venueId });

export const venueAccountingReportingPath = (venueId: string): string =>
  generatePath(`/${RouteDefinitions.Venue.path}${RouteDefinitions.Reporting.path}`, { venueId });

export const venueAccountingPayoutsPath = (venueId: string): string =>
  generatePath(`/${RouteDefinitions.Venue.path}${RouteDefinitions.Payouts.path}`, { venueId });

export const truncateBeforeSecondUuid = (url: string): string => {
  const hexDigit = '[0-9a-fA-F]';
  const uuidPattern = `${hexDigit}{8}-${hexDigit}{4}-${hexDigit}{4}-${hexDigit}{4}-${hexDigit}{12}`;
  const regex = new RegExp(`^(.*/venues/${uuidPattern}.*?)/${uuidPattern}`);

  return url.match(regex)?.[1] ?? url;
};

export const switchVenue = (venueId: string, oldVenueId: string, currentPath: string): string => {
  const truncatedPath = truncateBeforeSecondUuid(currentPath);
  return truncatedPath.replace(oldVenueId, venueId);
};

export const onboardingPath = (venueId: string): string =>
  generatePath(`/${RouteDefinitions.Venue.path}${RouteDefinitions.Onboarding.path}`, { venueId });


export const pongoOnboardingPath = (businessId: BusinessId): string =>
  generatePath(`/${RouteDefinitions.Venue.path}${RouteDefinitions.PongoLoyalty.path}`, { venueId: businessId });
