import styled from 'styled-components';
// eslint-disable-next-line @typescript-eslint/no-redeclare
import { colorUsage, spaceUsages, Text } from '@sundayapp/b2b-react-component-library';
import { MoneyView } from '@sundayapp/web-money';
import React from 'react';
import { FormattedMessage } from 'src/app/i18n/TypedIntl';
import { OrderingProduct } from 'src/ordering/orderingMenus/domain/OrderingProduct';
import { getSpacing } from 'src/ordering/stylesheet';
import { Checkbox } from '@mui/material';

export const ProductSelectionContainer = styled.div`
  display: flex;
  cursor: pointer;
  margin-bottom: ${getSpacing(1)};
`;

export const ProductDetails = styled.div`
  display: flex;
`;

export const ProductInfo = styled.div`
  padding-left: ${getSpacing(2)};
  display: flex;
  flex-direction: column;
`;

type UpsellProductProps = {
  product: OrderingProduct;
  isSelected: boolean;
  toggleProductSelection: (product: OrderingProduct, selected: boolean) => void;
};

export const UpsellProduct = ({ product, isSelected, toggleProductSelection }: UpsellProductProps) => (
  <ProductSelectionContainer>
    <Checkbox id={product.id} checked={isSelected} onChange={() => toggleProductSelection(product, !isSelected)} />
    <ProductInfo onClick={() => toggleProductSelection(product, !isSelected)}>
      <Text size="small" marginRight={spaceUsages.large}>
        {product.name}
      </Text>
      <ProductDetails>
        <Text size="xsmall" color={colorUsage.tertiaryText}>
          <MoneyView value={product.price} />
          &nbsp;
        </Text>
        <Text size="xsmall" color={colorUsage.tertiaryText}>
          &nbsp;/
          {' '}
          {product.sku || product.plu}
        </Text>
        {!product.imageUrl && (
          <Text size="xsmall" color={colorUsage.error}>
            &nbsp;
            {' '}
            <FormattedMessage id="upsell.noPhoto" defaultMessage="no photo configured" />
          </Text>
        )}
      </ProductDetails>
    </ProductInfo>
  </ProductSelectionContainer>
);
